import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "NavLang",

  setup() {
    const {
      locale
    } = useI18n();
    const value1 = ref(0);
    const option1 = [{
      text: "en",
      value: 0
    }, {
      text: "zh",
      value: 1
    }, {
      text: "mas",
      value: 2
    }];
    watch(value1, newValue => {
      option1.forEach(item => {
        if (item.value === newValue) {
          localStorage.setItem("language", item.text || "en"); // 切换多语言

          locale.value = item.text; // 设置给本地的i18n插件
        }
      });
    }, {
      immediate: true
    });
    return {
      value1,
      option1
    };
  }

};