module.exports = {
  form: {
    Welcome: "欢迎使用Jollynn防伪验证系统",
    JollynnSys: "Jollynn商品信息防伪验证系统",
    CheckInfo: "查询提示：您所查询的商品是正品!",
    Declare: "查询说明：",
    Declare1: "·若查询商品为正品则提示：您所查询的商品为正品",
    Declare2: "·若查询商品不存在则提示：您所查询的商品不存在，谨防假冒",
    Declare3: "·免责声明：本系统仅支持验证Jollynn产品",
    More: "打开产品手册查看更多款式",
  },
};
