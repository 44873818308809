import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "J_title"
};
const _hoisted_2 = {
  class: "check_info"
};
const _hoisted_3 = {
  class: "declare_info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_row = _resolveComponent("van-row");

  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  const _component_van_image = _resolveComponent("van-image");

  const _component_van_loading = _resolveComponent("van-loading");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, null, {
    title: _withCtx(() => [_createVNode(_component_van_row, {
      class: "nav_title"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("form.Welcome")), 1)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_van_row, {
    class: "lang_box"
  }, {
    default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent($setup.NavLang)))]),
    _: 1
  }), _createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_image, {
      round: "",
      class: "logo_goods",
      width: "2rem",
      height: "2rem",
      fit: "contain",
      src: "https://cloudoms.oss-ap-southeast-1.aliyuncs.com/goods_img/logo.png"
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("form.JollynnSys")), 1), _createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_image, {
      class: "logo_goods",
      width: "7rem",
      height: "7rem",
      fit: "cover",
      src: "https://cloudoms.oss-ap-southeast-1.aliyuncs.com/goods_img/brand.jpg"
    }, {
      loading: _withCtx(() => [_createVNode(_component_van_loading, {
        type: "spinner",
        size: "20"
      })]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("form.CheckInfo")), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, _toDisplayString(_ctx.$t("form.Declare")), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t("form.Declare1")), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t("form.Declare2")), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t("form.Declare3")), 1)])], 64);
}