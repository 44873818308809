import { ref } from "@vue/reactivity";
import { onMounted } from "vue"; // import { goodsQuerySku } from "@/api/goods";

import NavLang from "@/components/NavLang.vue"; // import { useRouter } from "vue-router";
// import config from "@/config/config";

export default {
  name: "SysApp",
  components: {
    NavLang
  },

  setup() {
    // let imgUrl = ref("");
    const NavLang = ref("NavLang"); // const router = useRouter();
    // let url = router.currentRoute.value.params.id;
    // const getGoodsImage = async () => {
    //   const res = await goodsQuerySku({
    //     skuCode: url,
    //     merchantId: config.id,
    //   });
    //   imgUrl.value = res.skuImageList[0].url || "";
    // };

    onMounted(() => {// getGoodsImage();
    }); // function onClickLeft() {
    //   imgUrl.value = "";
    //   window.opener = null;
    //   window.open("about:blank", "_top").close();
    // }

    return {
      // imgUrl,
      NavLang
    };
  }

};