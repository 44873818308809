import { createApp } from "vue";
import { Button, DropdownMenu, DropdownItem } from "vant";
import App from "./App.vue";
import i18n from "./assets/lang/index";
import "amfe-flexible";
import "./style/index.css";
import { createRouter, createWebHistory } from "vue-router";
import Sys from "@/views/Sys.vue";
// import Mal from "@/views/ProductManual.vue";
const routes = [
  {
    path: "/",
    name: "Sys",
    component: Sys,
  },
  // {
  //   path: "/ProductManual",
  //   name: "Mal",
  //   component: Mal,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(Button);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(i18n);
app.use(router);
app.mount("#app");
