module.exports = {
  form: {
    Welcome: "Selamat datang Jollynn sistem pengesahan anti-pemalsuan", //欢迎使用Jollynn防伪验证系统
    JollynnSys: "Jollynn sistem pengesahan anti-pemalsuan maklumat komoditi", //Jollynn商品信息防伪验证系统
    CheckInfo: "Petua Pertanyaan: Produk yang anda tanya adalah tulen!", //查询提示：您所查询的商品是正品!
    Declare: "Perihalan pertanyaan:", //查询说明：
    Declare1:
      "·Jika produk pertanyaan adalah tulen, ia akan menggesa: produk yang anda tanyakan adalah tulen", //若查询商品为正品则提示：您所查询的商品为正品
    Declare2:
      "·Jika produk pertanyaan tidak wujud, ia akan menggesa: produk yang anda tanyakan tidak wujud, berhati-hati terhadap pemalsuan", //若查询商品不存在则提示：您所查询的商品不存在，谨防假冒
    Declare3: "·Penafian: Sistem ini hanya menyokong pengesahan produk Jollynn", //免责声明：本系统仅支持验证Jollynn产品
    More: "Buka risalah produk untuk melihat lebih banyak gaya", //打开产品手册查看更多款式
  },
};
