import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_dropdown_item = _resolveComponent("van-dropdown-item");

  const _component_van_dropdown_menu = _resolveComponent("van-dropdown-menu");

  return _openBlock(), _createBlock(_component_van_dropdown_menu, {
    class: "lan_icon"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_dropdown_item, {
      modelValue: $setup.value1,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.value1 = $event),
      options: $setup.option1
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  });
}