module.exports = {
  form: {
    Welcome: "Welcome to Jollynn anti-counterfeiting verification system", //欢迎使用Jollynn防伪验证系统
    JollynnSys:
      "Jollynn commodity information anti-counterfeiting verification system", //Jollynn商品信息防伪验证系统
    CheckInfo: "Inquiry Tip: The product you are inquiring is genuine.", //查询提示：您所查询的商品是正品!
    Declare: "Query Description:", //查询说明：
    Declare1:
      "·If the query product is genuine, it will prompt: the product you are querying is genuine", //若查询商品为正品则提示：您所查询的商品为正品
    Declare2:
      "·If the query product does not exist, it will prompt: the product you are querying does not exist, beware of counterfeiting", //若查询商品不存在则提示：您所查询的商品不存在，谨防假冒
    Declare3:
      "·Disclaimer: This system only supports verification of Jollynn products", //免责声明：本系统仅支持验证Jollynn产品
    More: "Open product brochure to see more styles", //打开产品手册查看更多款式
  },
};
